body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Geist Variable';
  /* background-image: url('assets/img/brain_dots.svg'); */
  background-repeat: no-repeat;
  background-color: #fcfcfc;
  background-position: center;
  background-size: 3057px 1830px;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #406867;
  font-weight: bold;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.lds-ellipsis {
  position: relative;
  width: 64px;
  height: 32px;
}
.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #406867;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

.lds-ellipsis-little {
  position: relative;
  width: 32px;
  height: 64px;
}
.lds-ellipsis-little div {
  position: absolute;
  top: 0px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #406867;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis-little div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1-little 0.6s infinite;
}
.lds-ellipsis-little div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2-little 0.6s infinite;
}
.lds-ellipsis-little div:nth-child(3) {
  left: 15px;
  animation: lds-ellipsis2-little 0.6s infinite;
}
.lds-ellipsis-little div:nth-child(4) {
  left: 22px;
  animation: lds-ellipsis3-little 0.6s infinite;
}

@keyframes lds-ellipsis1-little {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3-little {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2-little {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(10px, 0);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

#country-menu div ul li {
  display: flex !important;
  justify-content: flex-start;
  padding: 12px;
}
#country-menu div ul li span.country-name {
  margin: 0px 8px;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#fc_frame {
  right: 50px !important;
  bottom: 20px !important;
}

.css-tlfecz-indicatorContainer {
  cursor: pointer;
}

input[type='time']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='time']::-webkit-clear-button {
  -webkit-appearance: none;
}
input[type='time']::-webkit-clear-button {
  display: none;
}
