/** InfoWindow */
.gm-style .gm-style-iw-c {
  background-color: white;
  border-radius: 0;
  min-width: 250px!important;
  max-height: 319px!important;
  padding-top: 16px!important;
  overflow-y: auto;
}
.gm-style .gm-style-iw-t::after {
  background: white; 
}
.gm-style .gm-style-iw-d {
  overflow: auto!important;
}

.gm-style iframe + div { border:none!important; }

